import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";
import Vue from "vue";

export default {
  install: (app, pluginConfig) => {
    Vue.prototype.$CookieConsent = CookieConsent;
    CookieConsent.run(pluginConfig);
  }
};
