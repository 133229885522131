var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"giga-simulation_card",class:{
      'active-alert':
        _vm.simulation.status === 'CARRIED_OUT' &&
        _vm.canValidateSimulation &&
        !_vm.simulation.isDraft
    },attrs:{"id":("sim-" + (_vm.simulation.name))}},[(
        _vm.simulation.status === 'CARRIED_OUT' &&
          _vm.canValidateSimulation &&
          !_vm.simulation.isDraft
      )?_c('div',{staticClass:"giga-simulation_alert"},[_c('div',{staticClass:"giga-simulation_alert_8"},[_c('label',{staticClass:"giga-simulation_alert_title"},[_vm._v(_vm._s(_vm.$t("send_to_leyton_or_delete_your_project")))])]),_c('div',{staticClass:"giga-simulation_alert_4"},[_c('ul',{staticClass:"giga-simulation_alert_actions"},[_c('li',{staticClass:"alert-action_item"},[_c('div',{staticClass:"giga-button"},[_c('button',{staticClass:"giga-green_button no-icon",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openConfirmModal('confirm-validation', _vm.simulation.id)}}},[_vm._v(" "+_vm._s(_vm.$t("send"))+" ")])])]),_c('li',{staticClass:"alert-action_item"},[_c('div',{staticClass:"giga-button"},[_c('button',{staticClass:"giga-white_outlined_button no-icon",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openConfirmModal('confirm-lose', _vm.simulation.id)}}},[_vm._v(" "+_vm._s(_vm.$t("delete"))+" ")])])])])])]):_vm._e(),_c('div',{staticClass:"giga-flexible_card",class:{ 'is-draft': _vm.simulation.isDraft }},[_c('div',{staticClass:"giga-flexible_card_header"},[_c('h3',{staticClass:"giga-flexible_card_title"},[_vm._v(_vm._s(_vm.simulation.name))]),(_vm.simulation.isDraft)?_c('img',{staticClass:"draft-icon",attrs:{"src":require("@/assets/images/draft-icon.png"),"alt":"draft-icon"}}):_c('span',{staticClass:"giga-flexible_card_badge"},[_vm._v(_vm._s(_vm.simulation.numberOfNotifications))]),_c('ul',{staticClass:"giga-simulation_process_statuses"},[_c('li',{staticClass:"giga-simulation_status",class:[
              _vm.simulation.status == 'NEW' ? 'done' : '',
              _vm.simulation.status == 'CARRIED_OUT' ? 'done' : '',
              _vm.simulation.status == 'TO_BE_TRANSFORMED' ? 'done' : ''
            ]},[_c('span',[_vm._v("Estimation Volume")])]),_c('li',{staticClass:"giga-simulation_status",class:[
              _vm.simulation.status == 'CARRIED_OUT' ? 'done' : '',
              _vm.simulation.status == 'TO_BE_TRANSFORMED' ? 'done' : ''
            ]},[_c('span',[_vm._v("Volume Estimé")])]),_c('li',{staticClass:"giga-simulation_status",class:{ done: _vm.simulation.status == 'TO_BE_TRANSFORMED' }},[_c('span',[_vm._v("En traitement")])])])]),_c('div',{staticClass:"giga-flexible_card_body"},[_c('div',{staticClass:"giga-flexible_card_details"},[(
              _vm.simulation.status !== 'CARRIED_OUT' &&
                _vm.simulation.status !== 'TO_BE_TRANSFORMED'
            )?_c('ul',{staticClass:"giga-simulation_card_details"},[_c('li',{staticClass:"giga-simulation_card_detail"},[_c('label',[_vm._v(_vm._s(_vm.$t("estimated_end_date")))]),_c('p',[_vm._v(" "+_vm._s(_vm.simulation.deliveryDate > 0 ? _vm.moment(_vm.simulation.deliveryDate, "x") .locale("fr") .format("DD MMMM YYYY") : _vm.moment() .locale("fr") .format("DD MMMM YYYY"))+" ")])])]):_vm._e(),(
              _vm.simulation.status === 'CARRIED_OUT' ||
                _vm.simulation.status === 'TO_BE_TRANSFORMED'
            )?_c('ul',[_c('li',{staticClass:"giga-simulation_card_detail"},[_c('label',[_vm._v(_vm._s(_vm.$t("Volume théorique classique")))]),_c('p',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.simulation.expectedThClassicVolume))+" kWh cumac ")])]),_c('li',{staticClass:"giga-simulation_card_detail"},[_c('label',[_vm._v(_vm._s(_vm.$t("Volume théorique précaire")))]),_c('p',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.simulation.expectedThPrecarityVolume))+" kWh cumac ")])]),_c('li',{staticClass:"giga-simulation_card_detail"},[_c('label',[_vm._v(_vm._s(_vm.$t("economic_valorisation")))]),(_vm.simulation.economicValorisation)?_c('p',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(_vm.simulation.economicValorisation))+" € ")]):_c('p',[_vm._v("En cours")])])]):_vm._e()]),_c('div',{staticClass:"giga-flexible_card_actions"},[(!_vm.isVIEWER)?_c('button',{staticClass:"work-card_edit_button",on:{"click":function($event){return _vm.onRowSelected(_vm.simulation.id)}}},[_vm._v(" Modifier ")]):_vm._e(),_c('button',{staticClass:"work-card_details_button",on:{"click":function($event){return _vm.onClickDetailSimulation(_vm.simulation.id)}}},[_vm._v(" Détail ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }